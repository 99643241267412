import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/galleries`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer toutes les galleries
export const getAllGalleries = async () => {
  try {
    const response = await axios.get(`${apiUrl}/getAllGalleries`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement des galleries:", error);
  }
};

// Fonction pour récupérer une gallerie par son id
export const getGalleryById = async (galleryId) => {
  try {
    const response = await axios.get(`${apiUrl}/getGallerieById/${galleryId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors du chargement de la gallerie:", error);
  }
};

/// POST
// Fonction pour ajouter une nouvelle gallerie
export const addGallery = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addGallerie`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout de la gallerie:", error);
  }
};

/// PUT
// Fonction pour mettre à jour une gallerie
export const updateGallery = async (galleryId, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/editGallerie/${galleryId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la gallerie:", error);
  }
};

/// DELETE
// Fonction pour supprimer une gallerie
export const deleteGallery = async (galleryId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteGallerie/${galleryId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Erreur lors de la suppression de la gallerie:", error);
  }
};
