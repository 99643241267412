import React, { useState, useEffect } from "react";
import { getBeltHistoryByAdherent } from "../../../services/beltServices";

const AdherentPassageCeinture = ({ adherentId }) => {
  const [ceintures, setCeintures] = useState([]);

  useEffect(() => {
    const fetchCeintures = async () => {
      try {
        const data = await getBeltHistoryByAdherent(adherentId);
        setCeintures(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des ceintures:", error);
      }
    };

    fetchCeintures();
  }, [adherentId]);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Passages de Ceintures</h3>
      <div className="w-full bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg overflow-x-auto">
        <table className="min-w-full bg-white dark:bg-gray-800">
          <thead>
            <tr>
              <th className="py-2 px-4 text-left font-medium text-gray-700 dark:text-gray-200 border-b-2">Date de Passage</th>
              <th className="py-2 px-4 text-left font-medium text-gray-700 dark:text-gray-200 border-b-2">Ceinture</th>
            </tr>
          </thead>
          <tbody>
            {ceintures.length > 0 ? (
              ceintures.map((ceinture, index) => (
                <tr key={index} className="hover:bg-gray-100 dark:hover:bg-gray-700">
                  <td className="py-2 px-4 border-b text-gray-700 dark:text-gray-300">
                    {new Date(ceinture.date_passage).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b text-gray-700 dark:text-gray-300">
                    {ceinture.BeltColor.nom || "Inconnu"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center py-4 text-gray-600 dark:text-gray-300">
                  Aucun historique de passage de ceinture disponible.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdherentPassageCeinture;
