import React, { useState, useEffect } from "react";
import { getPalmaresByAdherentId } from "../../../services/palmaresServices";

const AdherentPalmares = ({ adherentId }) => {
  const [palmaress, setPalmaress] = useState([]);

  useEffect(() => {
    const getPalmaresByAdherent = async () => {
      try {
        const data = await getPalmaresByAdherentId(adherentId);
        if (Array.isArray(data)) {
          setPalmaress(data);
        } else {
          throw new Error("Data received is not an array");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des palmarès:", error);
      }
    };

    getPalmaresByAdherent();
  }, [adherentId]);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Palmarès</h3>
      <div className="space-y-4 w-full">
        {palmaress.length > 0 ? (
          palmaress.map((palmares, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
                 {new Date(palmares.date_palmares).toLocaleDateString()} : {palmares.titre_palmares || "Inconnu"}
                </h4>
              </div>
              <div className="text-gray-700 dark:text-gray-300">
                {palmares.resultats_palmares && (
                  <div><strong>Résultats:</strong> {palmares.resultats_palmares}</div>
                )}
                {palmares.classement_palmares && (
                  <div><strong>Classement:</strong> {palmares.classement_palmares}</div>
                )}
                {palmares.observations_palmares && (
                  <div><strong>Observations:</strong> {palmares.observations_palmares}</div>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600 dark:text-gray-300">Aucun palmarès disponible.</p>
        )}
      </div>
    </div>
  );
};

export default AdherentPalmares;
