import React, { createContext, useContext, useState, useEffect } from "react";

const UserContext = createContext();

const useUser = () => useContext(UserContext);

const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Fonction sécurisée pour obtenir un item de localStorage et le parser en JSON
  const getLocalStorageItem = (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(
        `Erreur lors de la lecture de ${key} du localStorage:`,
        error
      );
      return null; // Retourne null en cas d'erreur de parsing
    }
  };

  // Fonction sécurisée pour définir un item dans localStorage
  const setLocalStorageItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(
        `Erreur lors de l'écriture dans ${key} du localStorage:`,
        error
      );
    }
  };

  // Méthode pour initialiser l'utilisateur à partir du localStorage lors du chargement de l'app
  useEffect(() => {
    const userData = getLocalStorageItem("userInfo");
    if (userData) {
      setUser(userData);
    }
  }, []);

  // Exemple de méthode pour mettre à jour l'utilisateur, incluant le stockage dans localStorage
  const updateUser = (userData) => {
    setLocalStorageItem("userInfo", userData); // Stocke les données utilisateur dans localStorage de manière sécurisée
    setUser(userData);
  };

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, useUser };
