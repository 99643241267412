import React, { useState } from "react";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import GooglePlayLogo from "./Google-Play-Logo.png";
import { useUser } from "../Home/UserContext";
import { getRoleFromRoleId } from "../../utils/roleHelper";
import DashboardNavigation from "../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../hooks/useScollToTop";

const images = [
  {
    src: "/images/judoscorepro/judoscore01.png",
    alt: "Capture 1",
    caption: "Tableau de score",
  },
  {
    src: "/images/judoscorepro/judoscore02.png",
    alt: "Capture 2",
    caption: "Mode d'emploi",
  },
  {
    src: "/images/judoscorepro/judoscore03.png",
    alt: "Capture 3",
    caption: "Réglages",
  },
  {
    src: "/images/judoscorepro/judoscore04.png",
    alt: "Capture 4",
    caption: "Sélection des langues",
  },
];

const JudoScorePro = () => {
  useScrollToTop();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const { src, alt, caption } = images[currentImageIndex];

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        VISITEURS & UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Judo Score Pro
      </h2>
      <DashboardNavigation role={userRole} />
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12 flex items-center justify-center">
        {/* Logo de l'application cliquable vers Google Play */}
        <a
          href="https://play.google.com/store/apps/details?id=com.victorin.judoscorepro"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/images/judoscorepro/logo_scoreboard_judo.png"
            alt="Logo JudoScore Pro"
            className="h-32 w-32 mr-8 cursor-pointer" // Ajout de la classe cursor-pointer pour indiquer que c'est cliquable
          />
        </a>
        <div>
          <p className="text-lg italic mt-2">
            Une application Android gratuite pour la tenue des scores de judo.
          </p>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-center md:items-start">
        {/* Carrousel */}
        <div className="md:w-1/2 w-full relative flex flex-col items-center justify-center mb-8 md:mb-0">
          {/* Bouton pour image précédente */}
          <button
            className="absolute left-4 bg-blue-600 dark:bg-blue-500 text-white p-2 rounded-full focus:outline-none"
            onClick={handlePrev}
          >
            <FaArrowLeft className="text-xl" />
          </button>

          {/* Conteneur d'image centré */}
          <div className="w-full max-w-4xl mx-auto flex justify-center">
            <img
              src={src}
              alt={alt}
              className="max-h-[600px] w-auto rounded-lg object-cover"
            />
          </div>

          {/* Légende sous l'image */}
          <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
            {caption}
          </p>

          {/* Bouton pour image suivante */}
          <button
            className="absolute right-4 bg-blue-600 dark:bg-blue-500 text-white p-2 rounded-full focus:outline-none"
            onClick={handleNext}
          >
            <FaArrowRight className="text-xl" />
          </button>
        </div>

        {/* Texte descriptif à droite du carrousel en mode desktop */}
        <div className="md:w-1/2 md:pl-12 text-center md:text-left">
          <h3 className="text-2xl font-semibold mb-4">Judo Score Pro</h3>
          <hr className="border-gray-400 dark:border-gray-700" />
          <p className="mt-4">
            JudoScore Pro vous permet de suivre et d'arbitrer les combats de
            judo en temps réel avec un tableau de score interactif. Idéal pour
            les compétitions et animations, l'application simplifie la gestion
            des scores, des chronomètres, et la désignation des vainqueurs.
          </p>
          <ul className="list-disc list-inside mt-4">
            <li>
              Tableau de score intuitif pour ajouter ou retirer des points et
              pénalités.
            </li>
            <li>Chronomètres personnalisables avec alertes sonores.</li>
            <li>
              Sélection de catégories de poids et d'âges pour les tirages au
              sort par équipe.
            </li>
            <li>
              Réglages de l'application : temps de combat, langue, sons, et type
              de compétition.
            </li>
          </ul>
          <p className="mt-4">
            Découvrez une solution simple et efficace pour gérer vos combats de
            judo avec JudoScore Pro.
          </p>

          <p className="mt-4">
            L'application est gratuite. Si vous souhaitez soutenir le
            développement d'autres applications liées au judo, ou bien mettre à
            disposition l'application sur l'Apple Store, vous pouvez contribuer.
          </p>

          <div className="mt-8 flex flex-col md:flex-row justify-center md:justify-start items-center">
            <a
              href="https://buy.stripe.com/6oEg1hajughs3Kw144" // Remplacez ce lien par votre lien Stripe
              target="_blank"
              rel="noopener noreferrer"
              className="px-6 py-3 bg-blue-600 hover:bg-blue-700 dark:bg-blue-500 dark:hover:bg-blue-600 text-white rounded-full transition mb-4 md:mb-0 md:mr-4"
            >
              Contribution 5€
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.victorin.judoscorepro"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={GooglePlayLogo}
                alt="Disponible sur Google Play"
                className="h-24"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JudoScorePro;
