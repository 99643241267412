import React from "react";
import formatDate from "../../../utils/formatDate";
import Button from "../../../components/Button/Button";
import { FaCreditCard } from "react-icons/fa";
import { FaCoins } from "react-icons/fa"; // Icône pour "Payer en plusieurs fois"

const ArticleTable = ({ articles, getStatusColor, defaultPhotoUrl, handlePayerClick, handlePayerEnPlusieursFoisClick }) => {
  return (
    <table className="min-w-full bg-white divide-y divide-gray-200 font-lato hidden sm:table">
      {articles.length > 0 ? (
        <tbody className="divide-y divide-gray-200 font-montserrat">
          {articles.map((article) => (
            <tr
              key={article.id}
              className="dark:bg-slate-200 hover:bg-slate-200 dark:hover:bg-slate-400 dark:hover:text-slate-200 cursor-pointer"
            >
              <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-500">
                {formatDate(article.date_achat)}
              </td>
              <td className="px-4 py-2 text-sm text-gray-500">
                <div className="font-semibold">
                  {`${article.quantite} x ${
                    article.article_id === null
                      ? "Règlement Licence-Cotisation"
                      : article.Article
                      ? article.Article.nom
                      : "Nom non disponible"
                  }`}
                </div>
                <div className="text-xs italic text-gray-800">
                  {article.message_achat}
                </div>
                <span
                  className={`inline-block my-2 px-3 py-1 rounded-full font-semibold text-xs ${getStatusColor(
                    article.statut_reglement
                  )}`}
                >
                  {article.statut_reglement}
                </span>
              </td>
              <td className="px-4 py-2 text-sm text-gray-500">
                {article.Article && article.Article.photo_url ? (
                  <img
                    src={article.Article.photo_url}
                    alt={
                      article.article_id === null
                        ? "Règlement Licence-Cotisation"
                        : article.Article.nom
                    }
                    className="w-32 h-32 object-contain aspect-square"
                  />
                ) : (
                  <img
                    src={defaultPhotoUrl}
                    alt="Image par défaut"
                    className="w-32 h-32 object-contain aspect-square"
                  />
                )}
              </td>
              <td className="px-4 py-2 min-w-[90px] whitespace-nowrap text-xl font-bold text-gray-500">{`${article.prix_total} €`}</td>
              <td className="text-center">
                <div className="flex">
                {(article.statut_reglement === "Non Réglé" || article.statut_reglement === "En Attente") && (
                  <>
                    <Button
                      text="Payer"
                      icon={FaCreditCard}
                      onClick={() => handlePayerClick(article.id)}
                      className="ml-4 gap-2"
                    />
                    {article.article_id === null && !article.message_achat.includes("Echéance") && (
                      <Button
                        text="Payer en plusieurs fois"
                        icon={FaCoins}
                        onClick={() => handlePayerEnPlusieursFoisClick(article.id)}
                        className="ml-4 gap-2 whitespace-nowrap"
                      />
                    )}
                  </>
                )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          <tr>
            <td colSpan="5" className="text-center py-4 px-4 dark:text-black">
              Pas d'articles commandés
            </td>
          </tr>
        </tbody>
      )}
    </table>
  );
};

export default ArticleTable;
