import React, { useEffect, useState } from 'react';
import { getUserIdByAdherentId } from '../../../services/adherentServices';
import { getRoles } from '../../../services/userServices';

const AdherentManagers = ({ adherentId }) => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    async function fetchData() {
      if (adherentId) {
        try {
          const userData = await getUserIdByAdherentId(adherentId);
          const rolesData = await getRoles();
          setUsers(userData);
          setRoles(rolesData);
        } catch (error) {
          console.error("Erreur lors de la récupération des utilisateurs:", error);
        }
      }
    }

    fetchData();
  }, [adherentId]);

  const getRoleName = (roleId) => {
    const role = roles.find(role => role.id === roleId);
    return role ? role.nom : 'Inconnu';
  };

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Gestionnaires</h2>
      <div className="space-y-4 w-full bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
        {users.length > 0 ? (
          users.map((user, index) => (
            <div key={index} className="flex items-center space-x-4 mb-4">
              <div className="flex-shrink-0">
                <img 
                  src={user.photoURL || '/img/user_avatar.jpg'} 
                  alt={`${user.prenom} ${user.nom}`} 
                  className="w-16 h-16 rounded-full object-cover shadow-lg"
                />
              </div>
              <div className="flex-grow">
                <p className="text-lg font-medium text-gray-900 dark:text-white">{user.nom} {user.prenom}</p>
                <p className="text-gray-600 dark:text-gray-300 text-xs">{user.email}</p>
                <p className="text-gray-600 dark:text-gray-300 text-xs">Rôle: {getRoleName(user.role_id)}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-gray-600 dark:text-gray-300">Aucun gestionnaire trouvé pour cet adhérent.</p>
        )}
      </div>
    </div>
  );
};

export default AdherentManagers;
