import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/inventaire`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer les objets de l'inventaire
export const getAllInventaire = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allInventaire`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la récupération des objets de l'inventaire:",
      error
    );
    throw error;
  }
};

/// POST
// Fonction pour ajouter un objet à l'inventaire
export const addInventaire = async (data) => {
  try {
    const response = await axios.post(`${apiUrl}/addInventaire`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Erreur lors de l'ajout d'un objet à l'inventaire:", error);
    throw error;
  }
};

/// PUT
// Fonction pour modifier un objet de l'inventaire
export const updateInventaire = async (inventaireId, data) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateInventaire/${inventaireId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la modification d'un objet de l'inventaire:",
      error
    );
    throw error;
  }
};

/// DELETE
// Fonction pour supprimer un objet de l'inventaire
export const deleteInventaire = async (inventaireId) => {
  try {
    const response = await axios.delete(
      `${apiUrl}/deleteInventaire/${inventaireId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(
      "Erreur lors de la suppression d'un objet de l'inventaire:",
      error
    );
    throw error;
  }
};
