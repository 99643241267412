import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrashAlt, FaComment } from 'react-icons/fa';
import EditNewsModal from './EditNewsModal';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getCommentsByNews, createComment, deleteComment } from './../../../services/commentnewsService';
import { deleteNews } from './../../../services/newsServices'; // Import de la fonction deleteNews
import { useUser } from './../../Home/UserContext';
import Button from './../../../components/Button/Button';

const NewsCard = ({ news, onDelete, canModify, refreshList }) => {
  const { user } = useUser();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [isCommentFormVisible, setIsCommentFormVisible] = useState(false);

  useEffect(() => {
    fetchComments();
  }, []);

  const fetchComments = async () => {
    try {
      const fetchedComments = await getCommentsByNews(news.id);
      setComments(fetchedComments);
    } catch (error) {
      console.error("Erreur lors du chargement des commentaires:", error);
    }
  };

  const handleAddComment = async () => {
    if (!user || !user.userId || !newComment) {
      console.error("Erreur: utilisateur non identifié ou commentaire vide.");
      return;
    }

    const commentData = {
      content: newComment,
      user_id: user.userId,
      news_id: news.id
    };

    try {
      await createComment(commentData);
      setNewComment('');
      setIsCommentFormVisible(false);
      fetchComments();
    } catch (error) {
      console.error("Erreur lors de la création du commentaire:", error);
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await deleteComment(commentId);
      fetchComments();
    } catch (error) {
      console.error("Erreur lors de la suppression du commentaire:", error);
    }
  };

  const handleEdit = () => setIsEditModalOpen(true);
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    refreshList();
  };

  const handleDeleteNews = async () => {
    try {
      await deleteNews(news.id);
      refreshList();
    } catch (error) {
      console.error("Erreur lors de la suppression de l'actualité:", error);
    }
  };

  const getUserInfoFromLocalStorage = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return userInfo || {};
  };

  const { role_id } = getUserInfoFromLocalStorage();

  const cardBackgroundClass = news.is_active ? 'bg-white' : 'bg-gray-400';

  return (
    <div className={`flex flex-col md:flex-row ${cardBackgroundClass} bg-opacity-80 rounded-lg overflow-hidden shadow-md relative m-4`}>
      <img src={news.image_url} alt={news.title} className='md:w-1/2 object-cover rounded-t-lg md:rounded-tr-none md:rounded-l-lg max-h-72 w-full h-full' />
      <div className='p-4 flex flex-col justify-between'>
        <div>
          <h3 className='text-lg font-bold uppercase text-gray-800 my-2 w-full font-montserrat'>{news.title}</h3>
          <h5 className='text-xs mb-3 text-black bg-backgroundMain w-full bg-gray-200 p-2'>
            {news.author?.prenom} {news.author?.nom}
          </h5>
          <div className='text-gray-600 text-justify w-full mb-4' dangerouslySetInnerHTML={{ __html: news.content }} />
        </div>
        <div className='flex flex-row justify-between'>
          <div className='flex gap-2'>
            {canModify && (
              <>
                <button onClick={handleEdit} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                  <FaEdit />
                </button>
                <button onClick={handleDeleteNews} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                  <FaTrashAlt />
                </button>
              </>
            )}
            <button onClick={() => setIsCommentFormVisible(!isCommentFormVisible)} className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
              <FaComment />
            </button>
          </div>
          {comments.length > 0 && (
            <div className='text-gray-400 font-bold hover:text-primary hover:font-bold flex'>
              <span onClick={() => setIsCommentsVisible(!isCommentsVisible)} className='cursor-pointer text-sm mr-2'>
                Commentaires : {comments.length}
              </span>
               <FaComment />
            </div>
          )}
        </div>
        {isCommentsVisible && (
          <div className='mt-4'>
            {comments.map(comment => (
              <div key={comment.id} className='bg-gray-100 p-2 mb-2 rounded flex items-center justify-between'>
                <div className='flex flex-col'>
                  <div dangerouslySetInnerHTML={{ __html: comment.content }} className='text-gray-800'></div>
                  <p className='text-gray-500 text-xs'>{comment.commenter?.prenom} {comment.commenter?.nom}</p>
                </div>
                {(comment.user_id === user.userId || role_id === 1 || role_id === 3) && (
                  <button onClick={() => handleDeleteComment(comment.id)} className='text-red-500 text-xs'>
                    <FaTrashAlt />
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
        {isCommentFormVisible && (
          <div className='mt-4'>
            <ReactQuill value={newComment} onChange={setNewComment} />
            <Button text="Ajouter Commentaire" onClick={handleAddComment} className="mt-2" />
          </div>
        )}
        <EditNewsModal isOpen={isEditModalOpen} onClose={closeEditModal} refreshList={refreshList} news={news} />
      </div>
    </div>
  );
};

export default NewsCard;
