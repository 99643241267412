// Catégorie de poids
function getWeightCategory(gender, ageCategory, weight) {
  const weightCategories = {
    Benjamin: [30, 34, 38, 42, 46, 50, 55, 60, 66],
    Minime: [34, 38, 42, 46, 50, 55, 60, 66, 73],
    Cadet: [46, 50, 55, 60, 66, 73, 81, 90],
    Junior: [55, 60, 66, 73, 81, 90, 100],
    Sénior: [60, 66, 73, 81, 90, 100],
    Vétéran: [60, 66, 73, 81, 90, 100],
    BenjaminF: [32, 36, 40, 44, 48, 52, 57, 63],
    MinimeF: [36, 40, 44, 48, 52, 57, 63, 70],
    CadetF: [40, 44, 48, 52, 57, 63, 70],
    JuniorF: [44, 48, 52, 57, 63, 70, 78],
    SéniorF: [48, 52, 57, 63, 70, 78],
    VétéranF: [48, 52, 57, 63, 70, 78],
  };

  // Assurer que le poids est un nombre
  let numericWeight = parseFloat(weight);

  // Si le poids est null, undefined, vide ou 0, retourne "Non pesé(e)"
  if (
    weight === null ||
    weight === undefined ||
    weight === "" ||
    numericWeight === 0
  ) {
    return "Non pesé(e)";
  }

  // Si le poids est invalide (NaN), retourne une erreur
  if (isNaN(numericWeight)) {
    return "Poids invalide";
  }

  // Modifier la clé en fonction du genre
  let categoryKey;
  if (gender === "Masculin") {
    categoryKey = ageCategory; // Assurez-vous que cela correspond aux clés masculines
  } else {
    categoryKey = `${ageCategory}F`; // Assurez-vous que cela correspond aux clés féminines
  }

  // Gérer les catégories d'âge inconnues
  if (ageCategory === "Inconnu") {
    return "Catégorie d'âge inconnue";
  }

  // Gérer les catégories sans poids (Eveil, Mini-Poussin et Poussin)
  if (
    ageCategory === "Eveil" ||
    ageCategory === "Mini-Poussin" ||
    ageCategory === "Poussin"
  ) {
    return "GM"; // GM pour "Groupe Morphologique"
  }

  // Vérifier si la clé existe
  if (!weightCategories.hasOwnProperty(categoryKey)) {
    console.error(`Catégorie inconnue: ${categoryKey}`);
    return "Catégorie inconnue";
  }

  // Récupérer la dernière catégorie (la plus lourde) pour la comparaison
  let lastCategory =
    weightCategories[categoryKey][weightCategories[categoryKey].length - 1];

  // Si le poids est supérieur à la dernière catégorie, retourner cette catégorie
  if (numericWeight >= lastCategory) {
    return `+${lastCategory} kg`;
  }

  // Trouver la catégorie de poids appropriée
  let selectedCategory = weightCategories[categoryKey].find(
    (cat) => numericWeight < cat
  );

  return selectedCategory === null
    ? "Aucune catégorie"
    : `-${selectedCategory} kg`;
}

export default getWeightCategory;
