import axios from "axios";

const apiUrl = `${process.env.REACT_APP_API_URL}/api/team`;
const token = localStorage.getItem("token");

/// GET
// Fonction pour récupérer tous les membres de l'équipe
export const getAllTeamMembers = async () => {
  try {
    const response = await axios.get(`${apiUrl}/allTeam`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200) throw new Error("Failed to fetch data");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors du chargement des membres de l'équipe:", error);
    return []; // Renvoie un tableau vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// POST
// Fonction pour ajouter un membre à l'équipe
export const addTeamMember = async (newMember) => {
  try {
    const response = await axios.post(`${apiUrl}/addTeam`, newMember, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 201)
      throw new Error("Failed to add new team member");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error("Erreur lors de l'ajout d'un membre à l'équipe:", error);
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PUT
// Fonction pour modifier un membre de l'équipe avec son id
export const updateTeamMember = async (id, updatedMember) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateTeam/${id}`,
      updatedMember,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200)
      throw new Error("Failed to update team member");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la modification d'un membre de l'équipe:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

// Modifier l'ordre des membres de l'équipe
export const updateTeamOrder = async (team) => {
  try {
    const response = await axios.put(
      `${apiUrl}/updateOrderTeam`,
      { team },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update team order");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la modification de l'ordre des membres de l'équipe:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// PATCH
// Fonction pour mettre à jour l'image des membres de l'équipe
export const updateTeamMemberPhoto = async (memberId, file, token) => {
  const formData = new FormData();
  formData.append("image", file);

  try {
    const response = await axios.patch(
      `${apiUrl}/${memberId}/photo`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status !== 200) throw new Error("Failed to update data");

    return response.data;
  } catch (error) {
    console.error("Erreur lors de la mise à jour de la photo:", error);
    return ""; // Renvoie une chaîne vide en cas d'échec pour maintenir la cohérence du type de retour
  }
};

/// DELETE
// Fonction pour supprimer un membre de l'équipe avec son id
export const deleteTeamMember = async (id) => {
  try {
    const response = await axios.delete(`${apiUrl}/deleteTeam/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status !== 200)
      throw new Error("Failed to delete team member");
    return response.data; // Renvoie directement les données
  } catch (error) {
    console.error(
      "Erreur lors de la suppression d'un membre de l'équipe:",
      error
    );
    return null; // Renvoie null en cas d'échec pour maintenir la cohérence du type de retour
  }
};
