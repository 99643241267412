import React from 'react';
import { FaTrash } from 'react-icons/fa'; // Importer les icônes nécessaires


// ButtonDelete avec une icône de poubelle
const ButtonDelete = ({ onClick, className = '', hoverLabel = 'Supprimer'  }) => {
  return (
    <button
      onClick={onClick}
      aria-label={hoverLabel}
      title={hoverLabel} 
      className={`w-8 h-8 bg-red-500 text-white rounded-md shadow-sm transition duration-300 ease-in-out hover:bg-red-700 flex items-center justify-center ${className}`}
    >
      <FaTrash className='text-[12px]'/>
    </button>
  );
};

export default ButtonDelete;
