import React from 'react';
import { FaEnvelope, FaEnvelopeOpen } from 'react-icons/fa'; // Importer les icônes nécessaires

// ButtonEnvelopeToggle avec bascule entre deux états
const ButtonEnvelopeToggle = ({ isRead, onClick, className = '', disabled = false }) => {
  return (
    <button
      onClick={disabled ? null : onClick} // Désactiver onClick si disabled est true
      aria-label={isRead ? 'Marquer comme non lu' : 'Marquer comme lu'}
      title={isRead ? 'Marquer comme non lu' : 'Marquer comme lu'}
      className={`w-8 h-8 rounded-md shadow-sm transition duration-300 ease-in-out flex items-center justify-center 
        ${disabled ? 'bg-gray-400 text-gray-700 cursor-not-allowed' : 'bg-blue-500 text-white hover:bg-blue-700'} 
        ${className}`}
      disabled={disabled} // Ajout de l'attribut disabled
    >
      {isRead ? <FaEnvelopeOpen className="text-[12px]" /> : <FaEnvelope className="text-[12px]" />}
    </button>
  );
};

export default ButtonEnvelopeToggle;
