import React from 'react';
import { FaPhone, FaSms, FaEnvelope } from 'react-icons/fa';

const getStatusColor = (statut_inscription) => {
  switch (statut_inscription) {
    case "Licencié":
      return "bg-green-300";
    case "Licencié Hors Club":
      return "bg-green-200";
    case "Inscrit":
      return "bg-yellow-300";
    case "Cours d'essai":
      return "bg-red-300";
    case "Licencié - Arrêt":
      return "bg-slate-300";
    case "Ancien Licencié et Arrêt":
      return "bg-slate-300";
    case "Ancien Licencié":
      return "bg-slate-900 text-white";
    case "Non Licencié - Arrêt":
      return "bg-slate-500 text-white";
    default:
      return "bg-gray-100";
  }
};

const AdherentDetails = ({ adherent }) => {
  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-5xl mx-auto">
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-4">
        {/* Image adhérent */}
        <div className="w-full lg:w-1/3 mb-2 lg:mb-0">
          <img
            src={adherent.photo_url || '/img/user_avatar.jpg'}
            alt={`${adherent.prenom} ${adherent.nom}`}
            className="w-full h-auto lg:h-[300px] object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Cartouche à droite de la photo */}
        <div className="w-full lg:w-2/3 bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
          {/* Nom et prénom */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              {adherent.nom.toUpperCase()} {adherent.prenom}
            </h2>
            {/* Tag statut d'inscription */}
            <span className={`px-3 py-1 rounded-lg ${getStatusColor(adherent.statut_inscription)}`}>
              {adherent.statut_inscription}
            </span>
          </div>

          {/* Boutons d'action */}
          <div className="flex space-x-4 mb-4">
            <a href={`tel:${adherent.tel}`} className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg flex items-center space-x-2 hover:text-white">
              <FaPhone />
              <span>Appel</span>
            </a>
            <a href={`sms:${adherent.tel}`} className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded-lg flex items-center space-x-2 hover:text-white">
              <FaSms />
              <span>SMS</span>
            </a>
            <a href={`mailto:${adherent.email}`} className="bg-indigo-500 hover:bg-indigo-600 text-white py-2 px-4 rounded-lg flex items-center space-x-2 hover:text-white">
              <FaEnvelope />
              <span>Mail</span>
            </a>
          </div>

          {/* Autres informations de l'adhérent */}
          <div className="space-y-3">
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Téléphone :</span> {adherent.tel}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Téléphone :</span> {adherent.tel_secondaire}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Email :</span> {adherent.email}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Email :</span> {adherent.email_secondaire}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Adresse :</span> {adherent.adresse}, {adherent.cp} {adherent.commune}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Genre :</span> {adherent.genre}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Date de naissance :</span> {new Date(adherent.date_naissance).toLocaleDateString()}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Poids :</span> {adherent.poids} Kg</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Numéro de licence :</span> {adherent.licence_number}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Saison active :</span> {adherent.saison_active}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Notes adhérent :</span> {adherent.notes_adherent || 'Aucune'}</p>
            <p className="text-gray-700 dark:text-gray-200"><span className="font-medium">Notes enseignant :</span> {adherent.notes_enseignant || 'Aucune'}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdherentDetails;
