import React, { useState, useEffect } from "react";
import { getReglementInscriptionByIdAndSaison } from "../../../services/reglementinscriptionServices";

const ReglementInscription = ({ adherentId, saison }) => {
  const [reglement, setReglement] = useState(null);

  useEffect(() => {
    const fetchReglement = async () => {
      const data = await getReglementInscriptionByIdAndSaison(adherentId, saison);
      setReglement(data);
    };

    fetchReglement();
  }, [adherentId, saison]);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Règlement d'Inscription</h3>
      {reglement ? (
        <div className="space-y-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Saison :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.saison}</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Montant Licence Assurance :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.montant_lic_assurance} €</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Montant Cotisation :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.montant_cotisation} €</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Réduction Foyer :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.reduction_foyer} €</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Pass Sport :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.pass_sport} €</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Réduction Saison :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.reduction_saison} €</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Type de Règlement :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.type_reglement}</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Nombre de Règlements :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.nb_reglement}</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Statut du Règlement :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.statut_reglement}</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Observations :</span>
            <span className="text-gray-700 dark:text-gray-300">{reglement.observations || 'Aucune'}</span>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-600 dark:text-gray-300">Chargement des données...</p>
      )}
    </div>
  );
};

export default ReglementInscription;
