import { useState, useEffect } from "react";
import { getSettings } from "./../services/settingsServices";

let saisonActive = ""; // Variable globale pour stocker la saison active

const fetchSaisonActive = async () => {
  try {
    const settingsData = await getSettings();
    if (settingsData && settingsData.length > 0) {
      saisonActive = settingsData[0].saison_active;
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des paramètres :", error);
  }
};

// Appeler fetchSaisonActive pour récupérer la saison active lors du chargement
fetchSaisonActive();

// Fonction qui attribue une catégorie d'âge en fonction de l'année de naissance
const getCategory = (dateOfBirth) => {
  if (!dateOfBirth || !saisonActive) return "Inconnu";

  const birthYear = new Date(dateOfBirth).getFullYear();
  const startYear = parseInt(saisonActive.split("-")[0], 10); // Année de début de la saison

  // Définition des catégories par année de naissance pour la saison active
  if (birthYear >= startYear - 5 && birthYear <= startYear - 3) return "Eveil";
  if (birthYear >= startYear - 7 && birthYear <= startYear - 6)
    return "Mini-Poussin";
  if (birthYear >= startYear - 9 && birthYear <= startYear - 8)
    return "Poussin";
  if (birthYear >= startYear - 11 && birthYear <= startYear - 10)
    return "Benjamin";
  if (birthYear >= startYear - 13 && birthYear <= startYear - 12)
    return "Minime";
  if (birthYear >= startYear - 16 && birthYear <= startYear - 14)
    return "Cadet";
  if (birthYear >= startYear - 19 && birthYear <= startYear - 17)
    return "Junior";
  if (birthYear >= startYear - 29 && birthYear <= startYear - 20)
    return "Sénior"; // Séparation correcte
  if (birthYear <= startYear - 30) return "Vétéran"; // Séparation correcte

  return "Inconnu";
};

export default getCategory;
