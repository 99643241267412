import React, { useState, useEffect } from "react";
import {
  deleteMessageContactForm,
  markMessageContactForm,
  getAllMessagesContactForm,
} from "../../../services/contactformServices";
import { useContactForm } from "../../../hooks/ContactFormContext";
import { MdMessage } from "react-icons/md";
import ButtonEyeNoRead from "../../../components/Button/ButtonEyeNoRead";
import ButtonEyeRead from "../../../components/Button/ButtonEyeRead";
import ButtonDelete from "../../../components/Button/ButtonDelete";
import ButtonEnvelopeToggle from "../../../components/Button/ButtonEnvelopeToggle";
import "./styles.css";

const ContactFormDisplay = ({ userId }) => {
  const [messages, setMessages] = useState([]);
  const { unreadCountContactForm, setUnreadCountContactForm } =
    useContactForm();

  const loadMessages = async () => {
    if (!userId) {
      console.error("L'ID de l'utilisateur n'est pas trouvé");
      return;
    }

    try {
      const data = await getAllMessagesContactForm(userId);
      setMessages(data);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await deleteMessageContactForm(messageId);
      loadMessages(); // Recharger les messages après la suppression
    } catch (error) {
      console.error("Erreur lors de la suppression du message:", error);
    }
  };

  const toggleReadStatus = async (messageId) => {
    try {
      const message = messages.find((m) => m.id === messageId);
      if (!message) {
        console.error("Message non trouvé");
        return;
      }

      // Inverser l'état de lecture dans la base de données
      await markMessageContactForm(messageId);

      // Mettre à jour l'état local des messages
      setMessages((prevMessages) =>
        prevMessages.map((m) =>
          m.id === messageId ? { ...m, read_message: !m.read_message } : m
        )
      );

      // Mettre à jour le compteur de messages non lus
      setUnreadCountContactForm((prevCount) =>
        message.read_message ? prevCount + 1 : prevCount - 1
      );
    } catch (error) {
      console.error(
        "Erreur lors du basculement de l'état de lecture du message:",
        error
      );
    }
  };

  useEffect(() => {
    loadMessages();
  }, [userId]);

  return (
    <div className="overflow-x-auto shadow-xl pt-4 font-montserrat">
      <div className="flex justify-between items-center">
        <h3 className="card-title mb-2 ml-2">
          <div className="relative">
            <MdMessage
              className={`icon ${unreadCountContactForm > 0 ? "" : ""}`}
              title="Message du formulaire de contact"
            />
            {unreadCountContactForm > 0 && (
              <span className="absolute -top-2 -right-2 bg-primary text-white text-xs rounded-full px-2 py-0.5">
                {unreadCountContactForm}
              </span>
            )}
          </div>
          Messages du Formulaire
        </h3>
      </div>
      {messages.length > 0 ? (
        <>
          {/* Tableau pour mode desktop */}
          <div className="hidden sm:block overflow-x-auto">
            <table className="min-w-full bg-gray-50 divide-y divide-gray-400">
              <thead className="bg-gray-50">
                <tr className="bg-slate-400">
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Date
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Détails
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-right text-xs font-medium uppercase tracking-wider text-white"
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-300">
                {messages.map((message) => (
                  <tr
                    key={message.id}
                    className={`hover:bg-gray-300 ${
                      message.read_message ? "bg-gray-200" : "bg-gray-100"
                    }`}
                  >
                    <td className="px-2 py-2 whitespace-nowrap text-sm text-gray-900">
                      {new Date(message.created_at).toLocaleDateString(
                        "fr-FR",
                        {
                          year: "2-digit",
                          month: "2-digit",
                          day: "2-digit",
                        }
                      )}
                    </td>
                    <td className="px-2 py-2 text-sm text-gray-500">
                      <div className="font-semibold">{message.name}</div>
                      <div className="text-sm text-blue-800 font-bold italic">
                        {message.email}
                      </div>
                      <div className="text-sm">{message.message}</div>
                    </td>
                    <td className="px-2 py-2 whitespace-nowrap text-right text-sm font-medium gap-2 flex">
                      <ButtonEnvelopeToggle
                        onClick={() => toggleReadStatus(message.id)}
                        isRead={message.read_message}
                      />
                      <ButtonDelete
                        onClick={() => handleDeleteMessage(message.id)}
                        hoverLabel="Supprimer"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Cartes pour mode mobile */}
          <div className="block sm:hidden">
            {messages.map((message) => (
              <div
                key={message.id}
                className={`shadow-md rounded-lg p-4 mb-4 hover:bg-gray-300 ${
                  message.read_message ? "bg-gray-200" : "bg-white"
                }`}
              >
                <div className="flex justify-between items-center mb-2">
                  <span className="text-sm font-semibold text-gray-900">
                    {new Date(message.created_at).toLocaleDateString("fr-FR", {
                      year: "2-digit",
                      month: "2-digit",
                      day: "2-digit",
                    })}
                  </span>
                  <div className="flex space-x-2">
                    <ButtonEnvelopeToggle
                      onClick={() => toggleReadStatus(message.id)}
                      isRead={message.read_message}
                    />
                    <ButtonDelete
                      onClick={() => handleDeleteMessage(message.id)}
                      hoverLabel="Supprimer"
                    />
                  </div>
                </div>
                <div className="text-sm text-gray-500 mb-2">
                  <div className="font-semibold">{message.name}</div>
                  <div className="text-sm text-blue-800 font-bold italic">
                    {message.email}
                  </div>
                  <div className="text-sm">{message.message}</div>
                </div>
              </div>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center py-4">Aucuns messages.</div>
      )}
    </div>
  );
};

export default ContactFormDisplay;
