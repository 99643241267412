import React, { useState, useEffect } from "react";
import formatDate from "../../../utils/formatDate";
import { useUser } from "../../Home/UserContext";
import { getRoleFromRoleId } from "../../../utils/roleHelper";
import DashboardNavigation from "../../DashboardNavigation/DashboardNavigation";
import useScrollToTop from "../../../hooks/useScollToTop";
import { getReglementsByUser } from "../../../services/achatsServices";
import { createCheckoutSessionForArticles } from "../../../services/paymentServices";
import PaymentSuccess from "./PaymentSuccess.js";
import { getSettings } from "../../../services/settingsServices";
import ArticleTable from "./ArticleTable";
import ArticleCard from "./ArticleCard";
import PaymentPlanModal from "./PaymentPlanModal";

const UsersReglements = () => {
  useScrollToTop();
  const [reglements, setReglements] = useState([]);
  const { user } = useUser();
  const userRole = getRoleFromRoleId(user?.role_id);
  const userId = user?.userId;

  const [nonPaidArticles, setNonPaidArticles] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sessionId, setSessionId] = useState(null);
  const [defaultPhotoUrl, setDefaultPhotoUrl] = useState("");
  const [isPaymentPlanModalOpen, setIsPaymentPlanModalOpen] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [maxPayments, setMaxPayments] = useState(5); // Valeur par défaut
  const [paymentInterval, setPaymentInterval] = useState(1); // Valeur par défaut

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const settings = await getSettings();

        if (Array.isArray(settings) && settings.length > 0) {
          const defaultPhotoUrl = settings[0].photo_url_default_lic_cot;
          setDefaultPhotoUrl(defaultPhotoUrl || "");

          setMaxPayments(settings[0].max_payments || 5);
          setPaymentInterval(settings[0].payment_interval || 1);
        } else {
          console.log("Settings data is not in the expected format.");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des paramètres", error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    const fetchReglements = async () => {
      try {
        const response = await getReglementsByUser(userId);
        if (!response || response.length === 0) {
          console.error("Aucun règlement trouvé pour l'utilisateur");
          return;
        }
        const sortedReglements = response.sort(
          (a, b) => new Date(b.date_achat) - new Date(a.date_achat)
        );
        const reglementsAvecSaison = sortedReglements.map((reglement) => {
          return {
            ...reglement,
            saison: determineSeason(reglement.date_achat),
            nomArticle: reglement.Article
              ? reglement.Article.nom
              : "Règlement Inscription",
            photoUrl: reglement.Article
              ? reglement.Article.photo_url || defaultPhotoUrl
              : defaultPhotoUrl,
          };
        });
        setReglements(reglementsAvecSaison);

        const filteredArticles = reglementsAvecSaison.filter(
          (reg) => reg.statut_reglement === "Non Réglé"
        );
        setNonPaidArticles(filteredArticles);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des règlements de l'utilisateur",
          error
        );
      }
    };

    if (userId) {
      fetchReglements();
    }
  }, [userId, defaultPhotoUrl]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const sessionId = query.get("session_id");
    if (sessionId) {
      setSessionId(sessionId);
    }
  }, []);

  const handlePayerClick = async (articleId) => {
    try {
      const article = nonPaidArticles.find(
        (article) => article.id === articleId
      );
      const items = [
        {
          id: article.id,
          name: article.Article ? article.Article.nom : "Règlement Inscription",
          price: article.prix_total,
          quantity: article.quantite,
        },
      ];
      const url = await createCheckoutSessionForArticles(items);
      window.location.href = url;
    } catch (error) {
      console.error(
        "Erreur lors de la création de la session de paiement des articles:",
        error
      );
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Non Réglé":
        return "bg-red-500 text-white";
      case "Réglé":
        return "bg-green-500 text-white";
      case "En Attente":
        return "bg-yellow-300 text-black";
      case "Annulé":
        return "bg-black text-white";
      case "Remboursé":
        return "bg-purple-500 text-white";
      case "Partiellement Réglé":
        return "bg-green-200 text-black";
      default:
        return "bg-gray-200 text-black";
    }
  };

  const determineSeason = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const seasonStart = new Date(`${year}-08-01`);
    const seasonEnd = new Date(`${year + 1}-07-31`);
    if (date >= seasonStart && date <= seasonEnd) {
      return `Saison ${year}-${year + 1}`;
    } else {
      return `Saison ${year - 1}-${year}`;
    }
  };

  const handlePayerEnPlusieursFoisClick = (articleId) => {
    const article = nonPaidArticles.find((article) => article.id === articleId);
    setSelectedArticle(article);
    setIsPaymentPlanModalOpen(true);
  };

  const handlePaymentPlanSelection = (selectedPlan) => {
    console.log("Paiement en plusieurs fois sélectionné :", selectedPlan);
    // Ajouter la logique pour gérer le paiement en plusieurs fois ici
  };

  return (
    <div className="flex flex-col w-full mx-auto p-2 mt-[130px] sm:mt-[130px] md:mt-[150px] lg:mt-[150px] xl:mt-[150px] dark:text-white">
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-light tracking-[0.4em] text-gray-800 text-center dark:text-white mb-4">
        UTILISATEURS
      </h2>
      <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl font-bold tracking-widest text-gray-800 text-center dark:text-white mb-4 font-montserrat uppercase">
        Vos Commandes & Réglements
      </h2>
      <DashboardNavigation role={userRole} />

      {sessionId && <PaymentSuccess sessionId={sessionId} />}

      <div className="text-center p-4 bg-blue-100 rounded-lg shadow max-w-[800px] mx-auto mb-4 dark:text-gray-800">
        <p className="text-lg font-semibold mb-2">
          Bienvenue sur la page Règlements de vos articles et cotisations
        </p>
        <p className="text-sm text-left">
          Sur cette page, vous pouvez régler en ligne les articles commandés
          dans notre boutique, et les licences-cotisations pour la saison en
          cours.
        </p>
        <p className="text-sm text-left">
          Si le statut du règlement n'a pas changé après votre paiement,
          veuillez rafraichir la page pour voir les mises à jour.
        </p>
      </div>

      <div className="flex flex-col justify-center items-center mb-4 max-w-[1280px] w-full mx-auto font-montserrat">
        {/* Table View */}
        <div className="w-full p-2 border-gray-300">
          <ArticleTable
            articles={reglements}
            getStatusColor={getStatusColor}
            defaultPhotoUrl={defaultPhotoUrl}
            handlePayerClick={handlePayerClick}
            handlePayerEnPlusieursFoisClick={handlePayerEnPlusieursFoisClick}
          />
        </div>

        {/* Card View for Mobile */}
        <div className="w-full p-2 lg:ml-4">
          <ArticleCard
            articles={reglements}
            getStatusColor={getStatusColor}
            defaultPhotoUrl={defaultPhotoUrl}
            handlePayerClick={handlePayerClick}
            handlePayerEnPlusieursFoisClick={handlePayerEnPlusieursFoisClick}
          />
        </div>

        {isPaymentPlanModalOpen && selectedArticle && (
          <PaymentPlanModal
            isOpen={isPaymentPlanModalOpen}
            onClose={() => setIsPaymentPlanModalOpen(false)}
            totalAmount={selectedArticle.prix_total}
            maxPayments={maxPayments}
            paymentInterval={paymentInterval}
            handlePaymentPlanSelection={handlePaymentPlanSelection}
            article={selectedArticle}
          />
        )}
      </div>
    </div>
  );
};

export default UsersReglements;
