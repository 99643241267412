import React from "react";
import formatDate from "../../../utils/formatDate";
import Button from "../../../components/Button/Button";
import { FaCreditCard, FaCoins } from "react-icons/fa"; // Import de l'icône FaCoins

const ArticleCard = ({ articles, getStatusColor, defaultPhotoUrl, handlePayerClick, handlePayerEnPlusieursFoisClick }) => {
  return (
    <div className="sm:hidden">
      {articles.length > 0 ? (
        articles.map((article) => (
          <div
            key={article.id}
            className="bg-white dark:bg-slate-200 shadow-md rounded-lg mb-4 p-4 flex"
          >
            <div className="flex-1 pr-4">
              <div className="text-sm text-gray-500">
                {formatDate(article.date_achat)}
              </div>
              <div className="text-sm text-gray-500 font-semibold">
                {`${article.quantite} x ${
                  article.article_id === null
                    ? "Règlement Licence-Cotisation"
                    : article.Article
                    ? article.Article.nom
                    : "Nom non disponible"
                }`}
              </div>
              <div className="text-xs italic text-gray-800">
                {article.message_achat}
              </div>
              <div className="text-normal font-bold text-gray-500">{`${article.prix_total} €`}</div>
              <span
                className={`inline-block my-2 px-3 py-1 rounded-full font-semibold text-xs ${getStatusColor(
                  article.statut_reglement
                )}`}
              >
                {article.statut_reglement}
              </span>
              <div className="flex gap-2">
              {/* Affichage des boutons si le statut est "Non Réglé" */}
              {(article.statut_reglement === "Non Réglé" || article.statut_reglement === "En Attente") && (
                <>
                  <Button
                    text="Payer"
                    icon={FaCreditCard}
                    onClick={() => handlePayerClick(article.id)}
                    className="mt-2 gap-2"
                  />
                  {article.article_id === null && !article.message_achat.includes("Echéance") && (
                    <Button
                      text="Payer en plusieurs fois"
                      icon={FaCoins}
                      onClick={() => handlePayerEnPlusieursFoisClick(article.id)}
                      className="mt-2 gap-2 whitespace-nowrap"
                    />
                  )}
                </>
              )}
              </div>
            </div>
            {article.Article && article.Article.photo_url ? (
              <div className="w-32 h-32">
                <img
                  src={article.Article.photo_url}
                  alt={
                    article.article_id === null
                      ? "Règlement Licence-Cotisation"
                      : article.Article.nom
                  }
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            ) : (
              <div className="w-32 h-32">
                <img
                  src={defaultPhotoUrl}
                  alt="Règlement Licence-Cotisation"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="text-center py-4 px-4 dark:text-black">
          Pas d'articles commandés
        </div>
      )}
    </div>
  );
};

export default ArticleCard;
