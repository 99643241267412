import React, { useState, useEffect } from "react";
import { getAttendanceList } from "../../../services/attendanceServices";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

const AttendanceList = ({ adherentId }) => {
  const [attendances, setAttendances] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAttendances = async () => {
      try {
        const data = await getAttendanceList(adherentId);
        setAttendances(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des présences:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttendances();
  }, [adherentId]);

  const groupAttendancesByMonth = (attendances) => {
    const grouped = {};

    attendances.forEach((attendance) => {
      const date = new Date(attendance.date_presence);
      const month = date.toLocaleString("default", { month: "long", year: "numeric" });

      if (!grouped[month]) {
        grouped[month] = [];
      }

      grouped[month].push(attendance);
    });

    return grouped;
  };

  const groupedAttendances = groupAttendancesByMonth(attendances);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Présences et Absences</h3>
      {isLoading ? (
        <p className="text-center text-gray-600 dark:text-gray-300">Chargement des données...</p>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
          {Object.keys(groupedAttendances).map((month) => (
            <div key={month} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">{month}</h4>
              <ul className="text-gray-700 dark:text-gray-300 list-inside">
                {groupedAttendances[month].map((attendance) => (
                  <li key={attendance.id} className="mb-1 flex items-center">
                    <span className="mr-2">
                      {new Date(attendance.date_presence).toLocaleDateString()}
                    </span>
                    {attendance.presence ? (
                      <FaCheckCircle className="text-green-500" />
                    ) : (
                      <FaTimesCircle className="text-red-500" />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AttendanceList;
