import React, { useState, useEffect } from "react";
import { getEventsByAdherent } from "../../../services/eventServices";

const AdherentCompetitions = ({ adherentId }) => {
  const [competitions, setCompetitions] = useState([]);

  useEffect(() => {
    const fetchCompetitions = async () => {
      try {
        const data = await getEventsByAdherent(adherentId);
        if (Array.isArray(data.Events)) {
          setCompetitions(data.Events);
        } else {
          throw new Error("Data received is not an array");
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des compétitions:", error);
      }
    };

    fetchCompetitions();
  }, [adherentId]);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Inscriptions aux Compétitions</h3>
      <div className="space-y-4">
        {competitions.length > 0 ? (
          competitions.map((competition) => (
            <div key={competition.AdherentEvent.event_id} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
              <div className="flex justify-between items-center mb-2">
                <h4 className="text-lg font-semibold text-gray-900 dark:text-white">{competition.titre}</h4>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  {new Date(competition.start).toLocaleDateString("fr-FR", {
                    day: "numeric",
                    month: "long",
                    year: "numeric"
                  })}
                </span>
              </div>
              <div className="text-gray-700 dark:text-gray-300">
                <div className="mb-2">
                  <strong>Validation :</strong> {competition.AdherentEvent.is_validate ? "✅ Validé" : "❌ Non validé"}
                </div>
                {competition.AdherentEvent.resultats && (
                  <div><strong>Résultats :</strong> {competition.AdherentEvent.resultats}</div>
                )}
                {competition.AdherentEvent.classement && (
                  <div><strong>Classement :</strong> {competition.AdherentEvent.classement}</div>
                )}
                {competition.AdherentEvent.observations && (
                  <div><strong>Observations :</strong> {competition.AdherentEvent.observations}</div>
                )}
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600 dark:text-gray-300">Aucune inscription aux compétitions.</p>
        )}
      </div>
    </div>
  );
};

export default AdherentCompetitions;
