import React, { useState, useEffect } from "react";
import { FaUserFriends, FaRegListAlt, FaUsers } from "react-icons/fa";
import { RiNumbersFill } from "react-icons/ri";
import {
  comptageStatutLicencie,
  comptageInscriptionsTotales,
} from "../../../services/dashboardServices";
import { countUsers } from "../../../services/userServices";
import { countVisites } from "../../../services/visitesServices";

const StatisticsCards = () => {
  const [adherentsCount, setAdherentsCount] = useState(0);
  const [inscritsCount, setInscritsCount] = useState(0);
  const [usersCount, setUsersCount] = useState(0);
  const [visitesCount, setVisitesCount] = useState(0);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const adherentsResponse = await comptageStatutLicencie();
        setAdherentsCount(adherentsResponse.nombreAdherents); // Utilisez la propriété de l'objet retourné

        const inscritsResponse = await comptageInscriptionsTotales();
        setInscritsCount(inscritsResponse.nombreInscrits); // Utilisez la propriété de l'objet retourné

        const usersResponse = await countUsers(); // Supposons que cela retourne un objet avec une propriété 'count'
        setUsersCount(usersResponse.count); // Utilisez la propriété 'count' de l'objet retourné
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques", error);
      }
      const visitesResponse = await countVisites();
      setVisitesCount(visitesResponse.count); // Utilisez la propriété 'count' de l'objet retourné
    };

    fetchCounts();
  }, []);

  return (
    <div className="flex flex-wrap justify-center items-center mx-auto px-4 gap-5 w-full mb-4 dark:bg-gray-600">
      {/* Définition des cartes avec une largeur minimale et adaptabilité pour les mobiles */}
      <div className="flex p-4 bg-yellow-300 rounded-xl shadow-xl flex-col items-center space-y-2 min-w-[250px] w-full md:w-auto dark:bg-yellow-600 dark:text-gray-200">
        <FaUserFriends className="text-black dark:text-white text-4xl" />
        <p className="text-black dark:text-white text-3xl font-bold">
          {adherentsCount}
        </p>
        <p className="text-gray-800 dark:text-gray-200 font-semibold">
          Adhérents Licenciés
        </p>
      </div>
      <div className="flex p-4 bg-red-300 rounded-xl shadow-xl flex-col items-center space-y-2 min-w-[250px] w-full md:w-auto dark:bg-red-600 dark:text-gray-200">
        <FaRegListAlt className="text-black dark:text-white text-4xl" />
        <p className="text-black dark:text-white text-3xl font-bold">
          {inscritsCount}
        </p>
        <p className="text-gray-800 dark:text-gray-200 font-semibold">
          Inscrits & Cours d'Essai
        </p>
      </div>
      <div className="flex p-4 bg-blue-300 rounded-xl shadow-xl flex-col items-center space-y-2 min-w-[250px] w-full md:w-auto dark:bg-blue-600 dark:text-gray-200">
        <FaUsers className="text-black dark:text-white text-4xl" />
        <p className="text-black dark:text-white text-3xl font-bold">
          {usersCount}
        </p>
        <p className="text-gray-800 dark:text-gray-200 font-semibold">
          Utilisateurs
        </p>
      </div>
      <div className="flex p-4 bg-gray-300 rounded-xl shadow-xl flex-col items-center space-y-2 min-w-[250px] w-full md:w-auto dark:bg-gray-600 dark:text-gray-200">
        <RiNumbersFill className="text-black dark:text-white text-4xl" />
        <p className="text-black dark:text-white text-3xl font-bold">
          {visitesCount}
        </p>
        <p className="text-gray-800 dark:text-gray-200 font-semibold">
          Visites
        </p>
      </div>
    </div>
  );
};

export default StatisticsCards;
