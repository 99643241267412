import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import Button from "../../../components/Button/Button";
import { format } from "date-fns"; // Pour formater les dates
import {
  updateReglement,
  createAchatsFractionnes,
} from "../../../services/achatsServices";
import { sendNotification } from "../../../services/notificationServices";

const PaymentPlanModal = ({
  isOpen,
  onClose,
  totalAmount,
  maxPayments,
  paymentInterval,
  handlePaymentPlanSelection,
  article,
  refreshDataAfterUpdate,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [installments, setInstallments] = useState([]);
  const [token, setToken] = useState(""); // Ajout du token

  useEffect(() => {
    // Supposons que le token soit stocké localement
    const storedToken = localStorage.getItem("token");
    setToken(storedToken);
  }, []);

  const handlePlanClick = (plan) => {
    setSelectedPlan(plan);

    // Calculer les échéances
    const installmentAmount = (totalAmount / plan).toFixed(2);
    const today = new Date();
    const newInstallments = [];

    for (let i = 0; i < plan; i++) {
      const dueDate = new Date(today);
      dueDate.setMonth(today.getMonth() + i * paymentInterval);
      newInstallments.push({
        date: format(dueDate, "dd/MM/yyyy"),
        amount: installmentAmount,
      });
    }

    setInstallments(newInstallments);
  };

  const handleConfirm = async () => {
    if (!selectedPlan || !article) return;

    try {
      // Calcul du montant de l'échéance
      const montantEcheance = (totalAmount / selectedPlan).toFixed(2);

      // 1. Mise à jour de l'achat initial
      const updatedAchat = {
        user_achat_id: article.user_achat_id,
        article_id: article.article_id, // Cela peut être null, donc on laisse tel quel
        quantite: article.quantite,
        prix_total: montantEcheance, // Utilisation du montant de l'échéance
        date_achat: article.date_achat, // La date reste la même
        message_achat: `Echéance 1 : Règlement Licence-Cotisation`,
        statut_reglement: article.statut_reglement,
        type_reglement: article.type_reglement,
        type_achat: article.type_achat,
      };

      await updateReglement(article.id, updatedAchat);

      // 2. Création des achats fractionnés en une seule opération
      const createData = {
        user_achat_id: article.user_achat_id,
        original_achat_id: article.id,
        totalAmount,
        paymentPlan: selectedPlan,
        paymentInterval,
      };

      await createAchatsFractionnes(createData);

      // 3. Envoi des notifications pour chaque échéance (sauf la première qui est à confirmer plus tard)
      for (let i = 1; i < installments.length; i++) {
        const installment = installments[i];
        const message = `Merci de penser à faire le règlement n°${
          i + 1
        } pour la licence-cotisation d'un montant de ${
          installment.amount
        } € à la date du ${installment.date}. Bonne journée.`;

        // Envoi de la notification pour les échéances futures
        await sendNotification(message, article.user_achat_id, token);
      }

      console.log("Achat initial et échéances mises à jour avec succès");

      // Rafraîchir les données après la confirmation
      refreshDataAfterUpdate();

      handlePaymentPlanSelection(selectedPlan);
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour et de la création des achats :",
        error
      );
    }

    onClose();
    // refresh windows
    window.location.reload();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Règlement en plusieurs fois"
    >
      <div className="p-4">
        <p className="text-lg font-semibold mb-4">
          Montant total : {totalAmount} €
        </p>
        <div className="flex space-x-2 mb-4">
          {[...Array(maxPayments - 1)].map((_, index) => (
            <Button
              key={index + 2}
              text={`x${index + 2}`}
              onClick={() => handlePlanClick(index + 2)}
              className={
                selectedPlan === index + 2 ? "bg-blue-500 text-white" : ""
              }
            />
          ))}
        </div>

        {installments.length > 0 && (
          <div className="mt-4">
            <h3 className="text-md font-semibold">Détails des échéances :</h3>
            <ul className="list-disc list-inside mt-2">
              {installments.map((installment, index) => (
                <li key={index} className="text-sm">
                  Échéance {index + 1} - {installment.date} :{" "}
                  {installment.amount} €
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="mt-6 flex justify-end">
          <Button
            text="Confirmer"
            onClick={handleConfirm}
            className="bg-green-500 text-white"
            disabled={!selectedPlan}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PaymentPlanModal;
