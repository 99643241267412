import React, { useState, useEffect } from "react";
import { getUserIdByAdherentId } from "../../../services/adherentServices";
import { getReglementsByUser } from "../../../services/achatsServices";

const AchatsAdherent = ({ adherentId }) => {
  const [achats, setAchats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getStatusColor = (status) => {
    switch (status) {
      case "Non Réglé":
        return "bg-red-500 text-white text-sm";
      case "Réglé":
        return "bg-green-500 text-white text-sm";
      case "En Attente":
        return "bg-yellow-300 text-black text-sm";
      case "Annulé":
        return "bg-black text-white text-sm";
      case "Remboursé":
        return "bg-purple-500 text-white text-sm";
      case "Partiellement Réglé":
        return "bg-green-200 text-black text-sm";
      default:
        return "bg-gray-200 text-black text-sm";
    }
  };

  useEffect(() => {
    const fetchAchats = async () => {
      try {
        const userObjects = await getUserIdByAdherentId(adherentId);
        let achatsData = [];

        for (const userObject of userObjects) {
          const userId = userObject.UserAdherent?.user_id;
          const userAchats = await getReglementsByUser(userId);
          if (Array.isArray(userAchats)) {
            achatsData = [...achatsData, ...userAchats];
          }
        }

        setAchats(achatsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des achats:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAchats();
  }, [adherentId]);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Achats & Licence-Cotisation</h3>
      {isLoading ? (
        <p className="text-center text-gray-600 dark:text-gray-300">Chargement des données...</p>
      ) : (
        <div className="space-y-4">
          {achats.length > 0 ? (
            achats.map((achat, index) => (
              <div key={index} className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white">
                    {achat.Article ? achat.Article.nom : "Licence-Assurance-Cotisation"}
                  </h4>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {new Date(achat.date_achat).toLocaleDateString()}
                  </span>
                </div>
                <div className="text-gray-700 dark:text-gray-300">
                  <div className="mb-2">
                    <strong>Utilisateur :</strong> {`${achat.User.prenom} ${achat.User.nom}`}
                  </div>
                  <div className="mb-2">
                    <strong>Montant :</strong> {achat.prix_total} €
                  </div>
                  <div className="mb-2">
                    <strong>Statut :</strong>{" "}
                    <span className={`px-2 py-1 rounded ${getStatusColor(achat.statut_reglement)}`}>
                      {achat.statut_reglement}
                    </span>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="text-center text-gray-600 dark:text-gray-300">Aucun achat trouvé.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default AchatsAdherent;
