import React, { useState, useEffect } from "react";
import { getDossierInscription } from "../../../services/dossierServices";
import { FaCheckCircle, FaTimesCircle, FaFileAlt, FaGoogleDrive } from "react-icons/fa";

const DossierInscription = ({ adherentId }) => {
  const [dossier, setDossier] = useState(null);

  useEffect(() => {
    const fetchDossier = async () => {
      const data = await getDossierInscription(adherentId);
      setDossier(data);
    };

    fetchDossier();
  }, [adherentId]);

  return (
    <div className="p-4 bg-gradient-to-r from-blue-200 to-indigo-300 rounded-lg shadow-lg mb-6 max-w-3xl mx-auto">
      <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">Dossier d'Inscription</h3>
      {dossier ? (
        <div className="space-y-4">
          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Validation Certificat Médical :</span>
            {dossier.validate_cm ? (
              <FaCheckCircle className="text-green-500 text-2xl" />
            ) : (
              <FaTimesCircle className="text-red-500 text-2xl" />
            )}
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Lien Certificat Médical :</span>
            <a href={dossier.cm_url} target="_blank" rel="noopener noreferrer">
              <FaFileAlt className="text-blue-500 text-2xl" />
            </a>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Date Certificat Médical :</span>
            <span className="text-gray-700 dark:text-gray-300">{new Date(dossier.date_cm).toLocaleDateString("fr-FR")}</span>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Formulaire Licence à Signer :</span>
            <a href={dossier.formulaire_licence_a_signer} target="_blank" rel="noopener noreferrer">
              <FaFileAlt className="text-blue-500 text-2xl" />
            </a>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Formulaire Licence Signé :</span>
            <a href={dossier.formulaire_licence_signe} target="_blank" rel="noopener noreferrer">
              <FaFileAlt className="text-blue-500 text-2xl" />
            </a>
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Validation Licence :</span>
            {dossier.validate_licence ? (
              <FaCheckCircle className="text-green-500 text-2xl" />
            ) : (
              <FaTimesCircle className="text-red-500 text-2xl" />
            )}
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Validation Règlement :</span>
            {dossier.validate_reglement ? (
              <FaCheckCircle className="text-green-500 text-2xl" />
            ) : (
              <FaTimesCircle className="text-red-500 text-2xl" />
            )}
          </div>

          <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg flex justify-between items-center">
            <span className="font-medium text-gray-700 dark:text-gray-200">Lien Google Drive :</span>
            <a href={dossier.lien_drive} target="_blank" rel="noopener noreferrer">
              <FaGoogleDrive className="text-blue-500 text-2xl" />
            </a>
          </div>
        </div>
      ) : (
        <p className="text-center text-gray-600 dark:text-gray-300">Chargement des données...</p>
      )}
    </div>
  );
};

export default DossierInscription;
